<template>
  <div class="myDeliver">
    <div class="page_title">
      我的投递
    </div>
    <div class="list_box">
      <template v-if="list_data.lists.length">
        <jobList :lists="list_data.lists" :numberRow="1" itemType="op" @changeData="getData"></jobList>
      </template>
      <el-empty :image-size="200" v-else></el-empty>

    </div>
    <Pager @changePage="changePage" @getList="getData" :page_size="page.page_size" :total="list_data.total"></Pager>
  </div>
</template>
  
<script>
import mixin from '../../../mixin'
import jobList from '../../../components/job/jobList.vue'
export default {
  mixins: [mixin],
  components: {
    jobList
  },
  data () {
    return {

    }
  },
  methods: {
    getData () {
      this.getList('getMySendList', this.page);
    },
  },
  created () {
    this.getData();
  },
  watch: {

  },
}
</script>
  
<style lang='scss'>
.myDeliver {
  padding: 26px;
  .page_title {
    padding-bottom: 12px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 22px;
    font-weight: 500;
    color: #2f2f2f;
    margin-bottom: 20px;
  }
  .list_box {
    min-height: 500px;
  }
}
</style>